import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag-next";

// createApp(App).use(store).use(router).mount('#app')

const app = createApp(App);

app.use(VueGtag, {
  property: {
    id: "UA-209682813-1"
  }
});

app.use(store).use(router).mount("#app");
