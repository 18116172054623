<template>
    <!-- MEDIUM -->
    <div class="relative hidden md:block">
        <img class="absolute" :src="require('@/assets/core/footer/footer.png')" alt="Red Pepper" />

        <a href="https://www.instagram.com/red_pepper_comunicacion/" target="_blank" class="block">
            <img class="absolute top-4 w-7" style="right:280px" :src="require('@/assets/core/footer/instagram.jpg')" alt="Red Pepper Instagram" />
        </a>
        <a href="https://www.facebook.com/RedPepperComunicacion/" target="_blank" class="block">
            <img class="absolute top-11 w-7" style="right:226px" :src="require('@/assets/core/footer/facebook.jpg')" alt="Red Pepper Facebook" />
        </a>
        <a href="https://www.linkedin.com/in/red-pepper-comunicaci%C3%B3n-sa-de-cv-29b3694b/" target="_blank" class="block">
            <img class="absolute top-8 w-7" style="right:130px" :src="require('@/assets/core/footer/linkedin.jpg')" alt="Red Pepper Linkedin" />
        </a>

        <div class="text-black absolute left-6 top-2">
            <a href="mailto:aclientes@redpepper.com.mx" target="_blank" class="font-semibold text-sm hover:text-rojo">
                aclientes@redpepper.com.mx
            </a>

            <div class="flex items-center font-semibold mb-0.5 text-sm space-x-3">
                <a href="tel:5517390637">55 1739 0637</a>
                <div class="w-2 h-2 rounded-full bg-rojo"></div>
                <a href="tel:5555501282">55 5550 1282</a>
                <!-- <div class="w-2 h-2 rounded-full bg-rojo"></div>
                <a href="tel:5556161333">55 5616 1333</a> -->
            </div>

            <div class="flex space-x-10 mt-3">
                <div class="space-y-1 text-xs">
                    <router-link to="/grandes-ideas" class="text-gray-500 hover:text-black block">GRANDES IDEAS</router-link>
                    <router-link to="/experiencias" class="text-gray-500 hover:text-black block">EXPERIENCIAS</router-link>
                    <router-link to="/lo-mejor" class="text-gray-500 hover:text-black block">LO MEJOR</router-link>
                    <router-link to="/nosotros" class="text-gray-500 hover:text-black block">NOSOTROS</router-link>
                    
                </div>
                <div class="space-y-1 text-xs">
                    <!-- <router-link to="/contacto" class="text-gray-500 hover:text-black block">CONTACTO</router-link> -->
                    <router-link to="/aviso-de-privacidad" class="text-gray-500 hover:text-black block">AVISO DE PRIVACIDAD</router-link>
                </div>
            </div>
        </div>
        
        <router-link to="/">
            <img class="absolute w-16" style="top:130px; left:280px" :src="require('@/assets/core/footer/logo_redpepper_blanco.png')" alt="Red Pepper" />
        </router-link>
    </div>

    <!-- CHIQUILLO -->
    <div class="md:hidden pt-4">
        <div class="flex justify-center items-center space-x-4 mt-4">
            <div class="w-9 h-9 rounded-full bg-white flex justify-center items-center">
                <a href="https://www.instagram.com/red_pepper_comunicacion/" target="_blank" class="block">
                    <img class="w-6" :src="require('@/assets/core/footer/instagram.jpg')" alt="Red Pepper Instagram" />
                </a>
            </div>
            <div class="w-9 h-9 rounded-full bg-white flex justify-center items-center">
                <a href="https://www.facebook.com/RedPepperComunicacion/" target="_blank" class="block">
                    <img class="w-6" :src="require('@/assets/core/footer/facebook.jpg')" alt="Red Pepper Facebook" />
                </a>
            </div>
            <div class="w-9 h-9 rounded-full bg-white flex justify-center items-center">
                <a href="https://www.linkedin.com/in/red-pepper-comunicaci%C3%B3n-sa-de-cv-29b3694b/" target="_blank" class="block">
                    <img class="w-6" :src="require('@/assets/core/footer/linkedin.jpg')" alt="Red Pepper Linkedin" />
                </a>
            </div>
        </div>

        <div class="text-center mt-4">
            <a href="mailto:aclientes@redpepper.com.mx" target="_blank" class="font-medium text-sm hover:text-rojo">
                aclientes@redpepper.com.mx
            </a>
        </div>

        <div class="flex justify-center items-center font-medium mt-2 text-sm space-x-2">
            <a href="tel:5517390637">55 1739 0637</a>
            <div class="w-1 h-1 rounded-full bg-rojo"></div>
            <a href="tel:5555501282">55 5550 1282</a>
            <!-- <div class="w-1 h-1 rounded-full bg-rojo"></div>
            <a href="tel:5556161333">55 5616 1333</a> -->
        </div>

        <div class="space-y-1 text-xs text-center mt-4">
            <router-link to="/grandes-ideas" class="text-white hover:text-rojo block">GRANDES IDEAS</router-link>
            <router-link to="/experiencias" class="text-white hover:text-rojo block">EXPERIENCIAS</router-link>
            <router-link to="/lo-mejor" class="text-white hover:text-rojo block">LO MEJOR</router-link>
            <router-link to="/nosotros" class="text-white hover:text-rojo block">NOSOTROS</router-link>
            <!-- <router-link to="/contacto" class="text-white hover:text-rojo block">CONTACTO</router-link> -->
            <router-link to="/aviso-de-privacidad" class="text-white hover:text-rojo block">AVISO DE PRIVACIDAD</router-link>
        </div>

        <img class="mt-4" :src="require('@/assets/core/footer/footer.png')" alt="Red Pepper" />
    </div>
</template> 