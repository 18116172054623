<template>
	<div class="max-w-5xl mx-auto mt-0 pt-0 text-white">
		<router-view />
		<!-- <Footer /> -->
	</div>
</template>

<script>
import 'tailwindcss/tailwind.css'
import Footer from '@/components/core/Footer'

export default {
	components:{
		Footer
	},
	setup() {
		
	},
}
</script>