import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from "vue-gtag-next"

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/Home.vue')
	},
	{
		path: '/grandes-ideas',
		name: 'GandesIdeas',
		component: () => import('@/views/GrandesIdeas.vue')
	},
	{
		path: '/experiencias',
		name: 'Experiencias',
		component: () => import('@/views/Experiencias.vue')
	},
	{
		path: '/lo-mejor',
		name: 'LoMejor',
		component: () => import('@/views/LoMejor.vue')
	},
	{
		path: '/experiencias/:slug',
		name: 'ExperienciasInt',
		component: () => import('@/views/Exper.vue')
	},
	{
		path: '/nosotros',
		name: 'Nosotros',
		component: () => import('@/views/Nosotros.vue')
	},
	// {
	// 	path: '/contacto',
	// 	name: 'Contacto',
	// 	component: () => import('@/views/Contacto.vue')
	// },
	{
		path: '/aviso-de-privacidad',
		name: 'AvisoDePrivacidad',
		component: () => import('@/views/Aviso.vue')
	},
	{
		path: '/:NotFound(.*)*', 
		component: () => import('@/views/NotFound.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

trackRouter(router);

export default router
